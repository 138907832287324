import { createMuiTheme } from "@material-ui/core";
import Cairo from "./assets/fonts/Cairo-Regular.ttf";
import CairoBold from "./assets/fonts/Cairo-Bold.ttf";
const cairo = {
  fontFamily: "Cairo",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Cairo'),
    local('Cairo-Regular'),
    url(${Cairo}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};
const bold = {
  fontFamily: "Cairo",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    local('Cairo'),
    local('Cairo-Bold'),
    url(${CairoBold}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

export const theme = createMuiTheme({
  palette: {
    text: {
      primary: "#FFF9F7",
      secondary: "#001427",
      disabled: "#CCC7C5",
      hint: "#FFE923"
    },
    primary: {
      main: "#001427"
    },
    secondary: {
      main: "#075E54"
    }
  },
  typography: {
    fontFamily: "Cairo, Arial",
    h1: {
      fontFamily: "Cairo-Bold, Arial"
    },
    h2: {
      fontFamily: "Cairo-Bold, Arial"
    },
    h3: {
      fontFamily: "Cairo-Bold, Arial"
    },
    h5: {
      fontFamily: "Cairo-Bold, Arial"
    },
    h6: {
      fontFamily: "Cairo, Arial"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [cairo, bold]
      }
    },
    MuiInputBase: {
      input: {
        color: "#001427"
      }
    }
  }
});
