import React, { useState } from "react";
import { Container, Typography, Box, Button } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";

function App() {
  const [open, setOpen] = useState(false);

  const handleTriggerDialog = () => setOpen(!open);

  const handleSendMsg = msg => {
    console.log(msg);
  };

  return (
    <div className="bg-primary-gradient fullheightVH">
      <Container className="">
        <Box className="flex ver flex-justify-center fullheightVH">
          <Typography variant="h3" align="center" className="py">
            رسالتك بالواتس أسهل
          </Typography>
          <Typography variant="body1" align="center" className="py">
            ماعندك واتس... ما طلع رقمك... سوي رفرش... حط صفرين...
          </Typography>
          <Typography variant="body1" align="center" className="py">
            إنسى كل هالمشاكل. رسالتك توصل مباشرة بدون إضافة ولا حفظ.
          </Typography>
          <Box className="center my">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              endIcon={
                <WhatsAppIcon style={{ marginRight: 20 }} fontSize="large" />
              }
              onClick={handleTriggerDialog}
              style={{ minWidth: "50%", maxWidth: "80%" }}
            >
              <Typography variant="h6">جـربـهـا</Typography>
            </Button>
          </Box>
        </Box>
      </Container>
      <MsgDialog
        open={open}
        triggerDialog={handleTriggerDialog}
        handleSend={handleSendMsg}
      />
    </div>
  );
}

const MsgDialog = ({ open, triggerDialog, handleSend }) => {
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");

  return (
    <Dialog
      open={open}
      onClose={triggerDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle disableTypography id="form-dialog-title">
        <Typography color="primary" align="center" variant="h6">
          رسـالـتـك
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            className="flex__centerlize"
            color="error"
            variant="caption"
          >
            إكتب رقم الجوال بدون أصفار أو علامة (+).
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="جوال المستلم"
          type="number"
          fullWidth
          value={phone}
          placeholder="مثال: 966555666777"
          onChange={e => setPhone(e.target.value)}
        />
        <TextField
          margin="dense"
          label="رسالتك (إختياري)"
          type="text"
          fullWidth
          value={text}
          onChange={e => setText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box className="flex hor flex-justify-between fullwidth">
          <Button variant="contained" onClick={triggerDialog} color="default">
            كنسل
          </Button>
          <Link
            to={phone.trim().length > 0 ? `//wa.me/${phone}?text=${text}` : "#"}
            target={phone.trim().length > 0 ? "_blank" : ""}
          >
            <Button variant="contained" color="secondary">
              أرسل
            </Button>
          </Link>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default App;
